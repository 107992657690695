@use 'common'

.wrapper
	margin: 0
	width: 100%
	height: 100%
	position: fixed
	background-color: common.$c-beige
	top: 0
	left: 0
	display: flex
	align-items: center
	justify-content: center
	z-index: 13
	opacity: 0
	transition: opacity .35s ease
	pointer-events: none

	&.open
		opacity: 1
		pointer-events: auto

.inner
	max-width: 848px
	width: 100%
	margin: 0 auto
	display: flex
	align-items: center
	background-color: #fff
	border: 1px solid common.$c-grey
	border-radius: 20px
	overflow: hidden
	position: relative
	flex-direction: row-reverse
	@media(max-width: 900px)
		max-width: 700px

.close
	background-color: transparent
	padding: 0
	border: none
	position: absolute
	top: 10px
	right: 10px

.image
	width: 50%
	height: 100%
	position: absolute
	top: 0
	left: 0

.content
	width: 50%
	padding: 60px 40px
	text-align: center

.title
	padding: 0
	margin: 0 0 15px

.description
	font-family: common.$f-epilogue
	color: common.$c-grey
	font-size: 14px
	line-height: 17px
	max-width: 230px
	margin: 0 auto
	margin-bottom: 14px

@media(max-width: 768px)
	.inner
		max-width: 95%
	.image
		display: none
	.content
		width: 100%
		padding: 30px
