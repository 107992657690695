$break360: 360px
$break480: 480px
$break560: 560px
$break700: 700px
$break768: 768px
$break1000: 1000px
$break1100: 1100px
$break1400: 1400px

$breakBinaryPollVisibilityMax: $break1000 - 1px

$f-epilogue: 'Epilogue', sans-serif
$f-montreal: 'PPNeueMontreal', sans-serif

$c-white: #ffffff
$c-grey: #7e7e7e
$c-beige: #f6f5ef
$c-orange: #ff6d00
$c-orange-dark: #d45c00
$c-blue-light: #e7f1fc
$c-blue: #0075FF
$c-green: #00FD5C
$c-dark: #2e2e2e
$c-btn-dark: $c-dark
$c-light-grey: #d4d4d4
$c-greyish: #dddddd
$c-brown: #44312f
$c-brown-dark: #2e2e2e
$c-beige: #EFECE6
$c-purple: #601F63
$c-purpleDark: rgba(#601F63, .3)

$image-background: rgba(#000000, .1)

$header-height-estimate: 55px

$image-transform-scale: scale(1.03)
$image-transform-transition: transform .8s ease

.rfm-marquee-container
    width: 100%
