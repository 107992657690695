@use 'common'

=normalizeSize($intrinsicWidth, $intrinsicHeight)
	width: common.em($intrinsicWidth, $intrinsicHeight)
	height: 1em

.wrapper
	//

.icon
	&-applePay
		+normalizeSize(512, 512)

	&-asterisk
		+normalizeSize(82, 76)

	&-fomoLogo
		+normalizeSize(104, 33)

	&-fomoLogoFooter
		+normalizeSize(478, 130)

	&-googlePay
		+normalizeSize(24, 24)

	&-microphone
		+normalizeSize(32, 22)

	&-user
		+normalizeSize(18, 18)

	&-play
		+normalizeSize(61, 61)

	&-plus
		+normalizeSize(78, 78)

	&-emoji
		+normalizeSize(23, 22)

	&-cross
		+normalizeSize(18, 18)

	&-magnifier
		+normalizeSize(25, 25)

	&-heart, &-heartFilled
		+normalizeSize(14, 12)
