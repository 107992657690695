html,
body
	padding: 0
	margin: 0
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

html
	height: -webkit-fill-available
	scroll-behavior: smooth

body
	display: grid
	grid-template-rows: 1fr auto // 1fr for content, auto for next-route-announcer
	min-height: 100vh
	min-height: -webkit-fill-available

#__next
	display: contents

a
	color: inherit

*, *::before, *::after
	box-sizing: border-box

button
	cursor: pointer

figure
	padding: 0
	margin: 0
