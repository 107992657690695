@use 'common'
@import './fonts'
@import './common'
@import './global/reset'
@import './global/theme'
@import './global/no-js-disable-transitions'
@import './global/typography'
@import './global/hacks'

// +debug()

body
    font-family: common.$f-montreal
