@use '../styles/common'

.wrapper
	margin: 0
	display: flex
	flex-direction: column

.input
	width: 100%
	background-color: common.$c-beige
	border: 1px solid common.$c-greyish
	padding: 13px 15px
	font-size: 14px
	border-radius: 10px
	margin-bottom: 10px
	color: #000
	&:placeholder
		color: common.$c-light-grey

.fieldset
	padding: 0
	border: none

.actions
	display: flex
	justify-content: space-between
	align-items: center
	margin-bottom: 20px
	flex-wrap: wrap
	input
		height: 11px
		width: 11px
		transform: translateY(1px)
	.checkbox, a, button
		font-size: 12px
		color: #000
		font-weight: 700
		user-select: none
		padding: 0
		background-color: transparent
		border: none
		text-decoration: underline
		font-family: common.$f-montreal

.error
	font-size: 14px
	margin: 10px 0 0 0
