@function repeat($character, $n)
	$c: ""
	@for $i from 1 through $n
		$c: $c + $character
	@return $c

// behave as important
html#{repeat("#html-el", 10)}.no-js
	*, *::before, *::after
		transition: none
