@forward './vars'
@forward './mixins/debug'
@forward 'sass:math'
@forward './functions/em'
@forward './functions/rem'
@forward './mixins/hideScrollbar'
@forward './mixins/horizontallyScrollableWithoutScrollbarOnSmallTouchDevice'
body.kiosek
	#didomi-host
		display: none
		pointer-events: none
body.kiosek.didomi-popup-open
	overflow: auto !important

body.didomi-popup-open-ios
	position: relative !important