
@font-face
	font-family: 'PPNeueMontreal'
	src: url('/fonts/PPNeueMontreal/PPNeueMontreal-Regular.woff2') format('woff2'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Regular.woff') format('woff'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Regular.ttf') format('truetype')
	font-weight: 450
	font-display: swap
	font-style: normal

@font-face
	font-family: 'PPNeueMontreal'
	src: url('/fonts/PPNeueMontreal/PPNeueMontreal-Book.woff2') format('woff2'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Book.woff') format('woff'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Regular.ttf') format('truetype')
	font-weight: 400
	font-display: swap
	font-style: normal

@font-face
	font-family: 'PPNeueMontreal'
	src: url('/fonts/PPNeueMontreal/PPNeueMontreal-Medium.woff2') format('woff2'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Medium.woff') format('woff'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Medium.ttf') format('truetype')
	font-weight: 500
	font-display: swap
	font-style: normal

@font-face
	font-family: 'PPNeueMontreal'
	src: url('/fonts/PPNeueMontreal/PPNeueMontreal-Italic.woff2') format('woff2'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Italic.woff') format('woff'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Medium.ttf') format('truetype')
	font-weight: 450
	font-display: swap
	font-style: italic

@font-face
	font-family: 'PPNeueMontreal'
	src: url('/fonts/PPNeueMontreal/PPNeueMontreal-Bold.woff2') format('woff2'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Bold.woff') format('woff'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Bold.ttf') format('truetype')
	font-weight: 700
	font-display: swap
	font-style: normal

@font-face
	font-family: 'PPNeueMontreal'
	src: url('/fonts/PPNeueMontreal/PPNeueMontreal-Bold.woff2') format('woff2'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Bold.woff') format('woff'), url('/fonts/PPNeueMontreal/PPNeueMontreal-Bold.ttf') format('truetype')
	font-weight: 800
	font-display: swap
	font-style: normal
