@use './common'

.Button
	--Button-horizontal-padding: 20px

	display: inline-block
	padding: 6px var(--Button-horizontal-padding)
	border-radius: 30px
	text-decoration: none
	cursor: pointer
	border: none
	font-size: 1rem
	transition-property: background-color, color
	transition-duration: .3s
	text-align: center
	overflow: hidden
	text-overflow: ellipsis
	font-family: common.$f-montreal
	font-weight: 700
	text-transform: uppercase

	@media (min-width: common.$break700)
		--Button-horizontal-padding: 40px

	&.shadow
		box-shadow: 0 0 3px rgb(0 0 0 / 5%), 1px 15px 20px -5px rgb(0 0 0 / 10%)

	&.large
		font-size: 1.4rem
		padding: 9px 32px

	&.view_type_dark
		background-color: common.$c-brown
		border: 2px solid common.$c-beige
		color: #fff

		&:hover, &:focus
			outline: 0
			background-color: common.$c-brown-dark

	&.view_type_green
		background-color: #00FD5C
		color: common.$c-btn-dark

	&.view_type_light
		background-color: #fff
		border: 1px solid common.$c-btn-dark
		font-weight: 800

		color: common.$c-btn-dark

		&:hover, &:focus
			outline: 0
			color: #fff
			background-color: common.$c-grey

	&.view_type_highlight
		background-color: common.$c-orange
		color: #ffffff

		&:hover, &:focus
			outline: 0
			background-color: common.$c-orange-dark

	&.view_type_textual
		background-color: transparent
		color: inherit
		text-decoration: underline

		&:hover, &:focus
			text-decoration: none
